export const hub = {
    channel: {
        app: {
            name: 'app',
            event: {
                info: 'info',
                error: 'error',
                success: 'success',
                warning: 'warning'
            }
        },
        news: {
            name: 'news',
            event: {
                news: 'news',
            }
        },
        userUpdated: {
            name: 'userUpdated',
            event: {
                userRefresh: 'userRefresh'
            }
        },
        auth: {
            name: 'auth',
            event: {
                signIn: 'signIn',
                signUp: 'signUp',
                signOut: 'signOut',
                signInFailure: 'signIn_failure',
                tokenRefresh: 'tokenRefresh',
                tokenRefreshFailure: 'tokenRefresh_failure',
                group: 'group',
                configured: 'configured',
                end: 'authEnd',
                start: 'authStart',
                setupQuestions: 'setupQuestions',
                submitQuestionsSuccess: 'submitQuestionsSuccess',
                startRandomQuestion: 'startRandomQuestion',
                answeredRandomQuestion: 'answeredRandomQuestion',
                invalidAnswer: 'invalidAnswer'
            }, 
            exception: {
                ExpiredCodeException: 'ExpiredCodeException',
                UserNotFoundException: 'UserNotFoundException',
                NotAuthorizedException: 'NotAuthorizedException',
                UsernameExistsException: 'UsernameExistsException',
                InvalidParameterException: 'InvalidParameterException', 
                InvalidPasswordException: 'InvalidPasswordException',
                UserNotConfirmedException: 'UserNotConfirmedException',
                TooManyFailedAttemptsException: 'TooManyFailedAttemptsException',
                PasswordResetRequiredException: 'PasswordResetRequiredException'
                
            }
        },
        http: {
            name: 'http',
            event: {
                end: 'end',
                start: 'start',
                error: 'error',
                success: 'success'
            }
        }
    }
}

export const cache = {
    username: 'username'
}

export const api = {
    webview: {
        name: 'WebViewAPI'
    },
    account: {
        name: 'AccountAPI'
    },
    contract: {
        name: 'ContractAPI'
    },
    pci: {
        name: 'PciAPI'
    },
    pciSubmitApi: {
        name: 'PciSubmitAPI'
    }
}

export enum AppOrigin {
    ACCOUNTS_ORIGIN = 'AccountModule',
    WEBVIEW_ORIGIN = 'WebView',
}
  


