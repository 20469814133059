import { Routes } from '@angular/router';
import { MaintenanceGuard } from './core/guards/maintenance.guard';

export const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./mepco-public/mepco-public.module').then(m => m.MepcoPublicModule),
    canActivate: [MaintenanceGuard],
    canActivateChild: [MaintenanceGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'webview',
    loadChildren: () => import('./mepco-webview/mepco-webview.module').then(m => m.MepcoWebviewModule),
  },
  {
    path: 'dealerPortal',
    loadChildren: () => import('./dealer-portal/dealer-portal.module').then(m => m.DealerPortalModule)
  },
  {
    path: 'fiPortal',
    loadChildren: () => import('./fi-portal/fi-portal.module').then(m => m.FiPortalModule)
  },
  {
    path: 'submitterPortal',
    loadChildren: () => import('./submitter-portal/submitter-portal.module').then(m => m.SubmitterPortalModule)
  },  
  {
    path: 'partnerApiPortal',
    loadChildren: () => import('./partner-api-portal/partner-api-portal.module').then(m => m.PartnerApiPortalModule)
  },  
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },

];
