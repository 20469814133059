export const route = {
  user: {
    path: 'user',
    name: 'User',
    module: {
      signIn: '',
      signUp: 'signup',
      signUpConfirm: 'signupconfrim',
      passwordReset: 'passwordreset',
      passwordResetConfirm: 'passwordresetconfirm',
    }
  },
  home: {
    path: '',
  },

  landing: {
    path: 'webview',
  },
  partnerApiPortal: {
    path: 'partnerApiPortal',
  },
  delarPortal: {
    path: 'dealerPortal',
  },
  fiPortal: {
    path: 'fiPortal',
  },
  submitterPortal: {
    path: 'submitterPortal',
    module: {
      submit: 'submit'
    }
  },
  contractEntry: {
    path: 'contractEntry',
    name: 'Contract Entry',
    module: {
      list: 'list',
      info: 'info',
      edit: 'edit',
      create: 'create'
    }
  },
  userAdministrationPortal: {
    path: 'userAdministration',
  },
  userAdministration: {
    path: 'user',
    name: 'User',
    module: {
      list: 'list',
      create: 'create',
      info: 'info'
    }
  },
  userPreferencesPortal: {
    path: 'userPreferences',
  },
  userPreferences: {
    path: 'preferences',
  },
  accounts: {
    path: 'accounts',
    module: {
      list: 'list'
    }
  },
  customerAccounts: {
    path: 'customerAccounts',
    module: {
      list: 'list'
    }
  },
  serviceRequests: {
    path: 'serviceRequests',
    module: {
      list: 'list'
    }
  },
  serviceRequestType: {
    path: 'servicerequesttype',
    module: {
      list: 'list',
    }
  },
  reports: {
    path: 'reports',
    module: {
      list: 'list',
      report: 'report'
    },
    apiLogReport: {
      path: 'reports/apiLog',
    },
    adHocReport: {
      path: 'reports/adhoc',
    },
    userActivityReport: {
      path: 'reports/userActivity',
    },
    failedLoginAttemptReport: {
      path: 'reports/failedLoginAttempt',
    },
    passwordResetReport: {
      path: 'reports/passwordReset',
    },
    ivrReport: {
      path: 'ivr/ivr-report',
    },
    dealerContract: {
      path: 'contractEntry/dealerContractReport',
    },
  },
  newsFeed: {
    path: 'newsFeed',
    module: {
      list: 'list',
    }
  },
  generateCRN: {
    path: 'generate-crn',
    module: {
      list: 'list',
    }
  },
}

