import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { log } from 'console';

@Injectable({providedIn: 'root'})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {    
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {   

        if (error.status === 429) {
          const errorMessage = 'Please Wait A Few Minutes Before You Try Again';
          return throwError(errorMessage);
        }
        
        return throwError(error);
      })
    );
  }
}

