import { Component, OnInit } from '@angular/core';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AppAuthService } from './core/services/app-auth.service';
import { AppRouteService } from './core/services/app-route.service';
import { AccountsFilterService } from './services/accounts/accounts-filter.service';
import { environment } from '@src/environments/environment';
import { route } from './app.route';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private idle: Idle,
    private appAuthService: AppAuthService,
    private appRouteService: AppRouteService,
    protected accountFilterService: AccountsFilterService,
    ) {

    this.idle.setIdle((Number(environment.idleTimeOut) || 600) / 2);
    this.idle.setTimeout((Number(environment.idleTimeOut) || 600) / 2);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
    });

    this.idle.onIdleEnd.subscribe(() => {
    });

    this.idle.onTimeout.subscribe(() => {
      this.onLogout();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
    });
  }

  onLogout(): void {
    this.appAuthService.execute(Auth.signOut())
      .then(() => {
        this.idle.stop();
        this.appRouteService.navigate(route.home.path).then(() => {
          window.location.reload();
        });
      });
  }

  ngOnInit(): void{
    Auth.currentAuthenticatedUser()
      .then((user: CognitoUser| any) => {
        if (user && !this.idle.isRunning()) {
          this.idle.watch(true);          
        }
      })
      .catch(err => {
        // User is not authenticated, you can handle the logic accordingly
    });
  }
}

