import { Injectable } from '@angular/core';
import { BehaviorSubject, Observer, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountsFilterService {

  private initialResponse = null;
  private accountFilterSubject = new BehaviorSubject(this.initialResponse);

  reset() {
    this.accountFilterSubject = new BehaviorSubject(this.initialResponse);
  }
  
  getAccountFilters() {
    return this.accountFilterSubject;
  }

  getAccountValue(){
    return this.accountFilterSubject.getValue();
  }

  setAccountFilters(details: any) {
    this.accountFilterSubject.next(details);
  }

}
