import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { BreadcrumbComponent } from '@src/app/shared/components/breadcrumb/breadcrumb.component';
import { ContractPrimaryHeaderComponent } from '@src/app/shared/contract-primary-header/contract-primary-header.component';
import { ChangePasswordDialogComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/change-password/change-password-dialog.component';
import { FooterComponent } from './footer/footer.component';
import { MaterialModule } from "../material.module";
import { DataTablesModule } from 'angular-datatables';
import { SnackBarComponent } from './components/snackbar/snackbar.component';
import { HubComponent } from './components/hub/hub.component';
import { TableComponent } from './components/table/table.component';
import { FilterComponent } from './components/table/filter.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { UserQuestionsAnswersComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/user-questions-answers/user-questions-answers.component';
import { UserAnswerComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/user-answer/user-answer.component';
import { IfRolesDirective } from '../core/directives/ifRoles.directive';
import { IfUserTypesDirective } from '../core/directives/ifUserTypes.directive';
import { ForceChangePasswordDialogComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/force-change-password/force-change-password-dialog.component';
import { ResetPasswordDialogComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/reset-password/reset-password-dialog.component';
import { ResetTempPasswordDialogComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/reset-temp-password/reset-temp-password-dialog.component';
import { AppApiService } from '../core/services/app-api.service';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { PaymentService } from '../mepco-webview/account/views/make-payment/services/payment.service';
import { SnackBarErrorComponent } from './components/snackbar/snackbar-error.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { NgxMaskModule } from 'ngx-mask';
import { DateFilterComponent } from './components/filter/date-filter.component';
import { QuillModule } from 'ngx-quill';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { MepcoDatePipe } from './pipes/mepco-date-pipe';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    ContractPrimaryHeaderComponent,
    ChangePasswordDialogComponent,
    ForceChangePasswordDialogComponent,
    ResetPasswordDialogComponent,
    ResetTempPasswordDialogComponent,
    FooterComponent,
    HubComponent,
    TableComponent,
    FilterComponent,
    DialogComponent,
    SnackBarComponent,
    SnackBarErrorComponent,
    UserQuestionsAnswersComponent,
    UserAnswerComponent,
    IfRolesDirective,
    IfUserTypesDirective,
    DateFilterComponent,
    TextEditorComponent,
    MepcoDatePipe
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    MaterialModule,
    FlexModule,
    MatPasswordStrengthModule.forRoot(),
    NgxMaskModule.forRoot(),
    FlexLayoutModule,
    QuillModule
  ],
  exports: [
    FooterComponent,
    BreadcrumbComponent,
    ContractPrimaryHeaderComponent,
    ChangePasswordDialogComponent,
    ForceChangePasswordDialogComponent,
    ResetPasswordDialogComponent,
    ResetTempPasswordDialogComponent,
    FormsModule,
    ReactiveFormsModule,
    TableComponent,
    MaterialModule,
    HubComponent,
    FilterComponent,
    DialogComponent,
    MaterialModule,
    SnackBarComponent,
    UserQuestionsAnswersComponent,
    UserAnswerComponent,
    IfRolesDirective,
    IfUserTypesDirective,
    DateFilterComponent,
    FlexModule,
    FlexLayoutModule,
    TextEditorComponent,
    MepcoDatePipe
  ],
  providers: [AppApiService,PaymentService
  ]
})

export class SharedModule {

}
