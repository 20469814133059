// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { api } from "src/app/app.const";
import { version } from "./version";

export const environment = {
  version: 'webview-' + process.env.NG_APP_ENVIRONMENT + '_v1.0.55.0',
  production: false,
  idleTimeOut: process.env.NG_APP_IDLE_TIMEOUT,
  amplify: {
    Auth: {
      region: process.env.NG_APP_MEPCO_REGION ,
      userPoolId: process.env.NG_APP_MEPCO_USER_POOL,
      userPoolWebClientId: process.env.NG_APP_MEPCO_CLIENT_ID,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: process.env.NG_APP_MEPCO_USER_POOL_DOMAIN,
        scope: [
          "email",
          "openid",
          "profile"
        ],
        redirectSignIn: process.env.NG_APP_OAUTH_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.NG_APP_OAUTH_REDIRECT_SIGN_OUT,
        responseType: "code"
      }
    },
    API: {
      endpoints: [
        {
          name: api.webview.name,
          endpoint: process.env.NG_APP_WEBVIEW_API_ENDPOINT
        },
        {
          name: api.account.name,
          endpoint: process.env.NG_APP_ACCOUNT_API_ENDPOINT
        },
        {
          name: api.contract.name,
          endpoint: process.env.NG_APP_CONTRACT_API_ENDPOINT
        },
        {
          name: api.pci.name,
          endpoint: process.env.NG_APP_PCI_API_ENDPOINT
        },
        {
          name: api.pciSubmitApi.name,
          endpoint: process.env.NG_APP_PCI_SUBMIT_API_ENDPOINT
        }
      ]
    }
  }
};