import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppLogService {

    public static log(
        text: any,
        color?: string): void {
        if (color) {
         
        } else {
         
        }
    }

    public static event(text: any): void {
        if (!environment.production) {
            
        }
    }

    public static info(text: any): void {
        if (!environment.production) {
           
        }
    }

    public static warn(text: any): void {
        if (!environment.production) {
            console.log('%c' + 'WARN--->>>', 'color:gold', text);
        }
    }

    public static error(text: any): void {
        
        if (!environment.production) {
            console.log('%c' + 'ERROR--->>>', 'color:red', text);
        }
    }

}
